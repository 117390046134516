import * as React from "react";
import {useForm, Controller, SubmitHandler} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {z} from "zod";

// Generate newa-api endpoints types with:
// npx openapi-typescript https://newa.cornell.edu/openapi.json
// or when developing the api locally: npx openapi-typescript http://localhost:8787/openapi.json
import {components, paths} from "../types/api"; // Generated from OpenAPI
import {useState} from "react";

// Import type from generated OpenAPI types
type PestDamageReportRequest = components["schemas"]["PestDamageReportRequest"];

// Environment variables with type safety
const API_BASE_URL = 'https://seedcorn-pest-damage-report.nysipm-admin.workers.dev';
const PEST_DAMAGE_ENDPOINT = `${API_BASE_URL}/seedcorn-pest-damage-report`;

// Zod schema for form validation
let zodDefinition = {
  // Contact Information
  contactName: z.string(),
  contactEmail: z.string().email("Invalid email address"),
  contactOrganization: z.string().optional(),
  consent: z.literal(true, {
    errorMap: () => ({message: "You must consent to submit this form"}),
  }),

  // Damage Information
  damageType: z.object({
    seedcornMaggot: z.boolean().optional(),
    wireworm: z.boolean().optional(),
    whiteGrubs: z.boolean().optional(),
    other: z.boolean().optional(),
  }).optional(),
  damageTypeOtherText: z.string().optional(),
  extensionVerified: z.enum(["yes", "no"]).optional(),
  extensionAffiliation: z.string().optional(),
  areaAffected: z.string().min(1, "Area affected is required"),
  damagePhotos: z.instanceof(Object).optional(),
  fieldPhotos: z.instanceof(Object).optional(),
  standLossMeasurement: z.string().optional(),
  needsReplanting: z.enum(["yes", "no"]).optional(),
  replantingDecision: z.string().optional(),

  // General Field Information
  state: z.string().min(1, "State is required"),
  county: z.string().min(1, "County is required"),
  fieldSize: z.number().positive("Field size must be positive"),

  // Field History
  cropLastSeason: z.string().optional(),
  coverCrop: z.enum(["yes", "no"]).optional(),
  coverCropMethod: z.enum(["drilled", "broadcasted"]).optional(),
  coverCropType: z.object({
    rye: z.boolean().optional(),
    clover: z.boolean().optional(),
    barley: z.boolean().optional(),
    radishes: z.boolean().optional(),
    alfalfa: z.boolean().optional(),
    turnips: z.boolean().optional(),
    other: z.boolean().optional(),
  }).optional(),
  coverCropTypeOther: z.string().optional(),
  coverCropTermination: z.object({
    rolling: z.boolean().optional(),
    mowing: z.boolean().optional(),
    burning: z.boolean().optional(),
    herbicides: z.boolean().optional(),
    incorporation: z.boolean().optional(),
    other: z.boolean().optional(),
  }).optional(),
  coverCropTerminationOther: z.string().optional(),
  coverCropTerminationDate: z.object({
    month: z.string().optional(),
    day: z.string().optional(),
    year: z.string().optional(),
  }).optional(),
  manure: z.enum(["no", "cow", "chicken", "other"]).optional(),
  manureOther: z.string().optional(),
  manureApplication: z.object({
    month: z.string().optional(),
    day: z.string().optional(),
    year: z.string().optional(),
  }).optional(),
  manureApplicationRate: z.string().optional(),
  tillage: z.enum(["yes", "no"]).optional(),
  tillageType: z.object({
    reduced: z.boolean().optional(),
    conventional: z.boolean().optional(),
    stripZonal: z.boolean().optional(),
    mouldboard: z.boolean().optional(),
    tined: z.boolean().optional(),
    disc: z.boolean().optional(),
  }).optional(),
  tillageDate: z.object({
    month: z.string().optional(),
    day: z.string().optional(),
    year: z.string().optional(),
  }).optional(),

  // Management
  plantingDate: z.object({
    month: z.string().optional(),
    day: z.string().optional(),
    year: z.string().optional(),
  }).optional(),
  seedVariety: z.string().optional(),
  seedingRate: z.string().optional(),
  rowSpacing: z.number().optional(),
  seedTreatment: z.object({
    cruiserMaxx: z.boolean().optional(),
    poncho: z.boolean().optional(),
    other: z.boolean().optional(),
  }).optional(),
  seedTreatmentOther: z.string().optional(),
};
if (typeof window !== "undefined") {
  zodDefinition = {
    ...zodDefinition,
    damagePhotos: z.instanceof(FileList).optional(),
    fieldPhotos: z.instanceof(FileList).optional(),
  }
}
const formSchema = z.object(zodDefinition);

// Extract type from the schema
type FormData = z.infer<typeof formSchema>;

// Type for API responses, imported from openapi generated types
type SubmitResponse = paths['/']['post']['responses']['200']['content']['application/json'];
type ErrorResponse =
  paths['/']['post']['responses']['400']['content']['application/json']
  | paths['/']['post']['responses']['500']['content']['application/json'];

// Custom hook for API call
function usePestDamageReportSubmit() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitResult, setSubmitResult] = useState<{
    type: "success" | "error";
    message: string;
    reportId?: number;
  } | null>(null);

  const submitForm = async (data: FormData) => {
    setIsSubmitting(true);

    try {
      // Create FormData object for multipart/form-data submission
      const formData = new FormData();

      // Helper function to flatten and append nested objects to FormData
      const appendToFormData = (obj: any, prefix = '') => {
        for (const key in obj) {
          const value = obj[key];
          const formKey = prefix ? `${prefix}.${key}` : key;

          if (value instanceof FileList) {
            for (let i = 0; i < value.length; i++) {
              formData.append(`${formKey}[${i}]`, value[i]);
            }
          } else if (value !== null && typeof value === 'object' && !(value instanceof File)) {
            appendToFormData(value, formKey);
          } else if (value !== undefined) {
            formData.append(formKey, String(value));
          }
        }
      };

      appendToFormData(data);

      // Make API call
      const response = await fetch(PEST_DAMAGE_ENDPOINT, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json() as ErrorResponse;
        throw new Error(errorData.message || 'Failed to submit report');
      }

      const result = await response.json() as SubmitResponse;

      setSubmitResult({
        type: 'success',
        message: result.message || 'Report submitted successfully!',
        reportId: result.reportId,
      });
      window.location.href = '#result';

      return true;
    } catch (error) {
      setSubmitResult({
        type: 'error',
        message: error instanceof Error ? error.message : 'An unknown error occurred',
      });

      return false;
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    submitForm,
    isSubmitting,
    submitResult,
  };
}

export const PestDamageReportForm: React.FC = () => {
  // Form hook with validation
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: {errors},
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    defaultValues: {
      damageType: {},
      coverCropType: {},
      coverCropTermination: {},
      tillageType: {},
      seedTreatment: {},
    }
  });

  // Custom hook for form submission
  const {submitForm, isSubmitting, submitResult} = usePestDamageReportSubmit();

  // Watch values for conditional rendering
  const extensionProviderVerified = watch("extensionVerified") === "yes";
  const needsReplanting = watch("needsReplanting") === "yes";
  const hasCoverCrop = watch("coverCrop") === "yes";
  const hasManure = watch("manure") !== "no" && watch("manure") !== undefined;
  const hasTillage = watch("tillage") === "yes";
  const manure = watch("manure");
  const damageTypeOther = watch("damageType.other");
  const coverCropTypeOther = watch("coverCropType.other");
  const coverCropTerminationOther = watch("coverCropTermination.other");
  const seedTreatmentOther = watch("seedTreatment.other");

  // Form submission handler
  const onSubmit: SubmitHandler<FormData> = async (data) => {
    await submitForm(data);
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-2xl md:text-3xl font-bold text-center mb-6">Pest Damage Report Form</h1>

      {submitResult && (
        <section id="result">
          <div
            className={`p-4 mb-6 rounded ${
              submitResult.type === "success"
                ? "bg-green-100 text-green-800"
                : "bg-red-100 text-red-800"
            }`}
          >
            {submitResult.message}
            {submitResult.reportId && (
              <div className="mt-2">Report ID: {submitResult.reportId}</div>
            )}
          </div>
        </section>
      )}

      {!submitResult && <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
        {/* Contact Information */}
        <section className="bg-gray-50 p-4 md:p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4 border-b pb-2">Contact Information</h2>

          <div className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="contactName" className="block text-sm font-medium mb-1">
                  Name
                </label>
                <input
                  type="text"
                  id="contactName"
                  {...register("contactName")}
                  className="w-full px-3 py-2 text-sm border rounded"
                />
              </div>

              <div>
                <label htmlFor="contactEmail" className="block text-sm font-medium mb-1">
                  Email
                </label>
                <input
                  type="email"
                  id="contactEmail"
                  {...register("contactEmail")}
                  className="w-full px-3 py-2 text-sm border rounded"
                />
                {errors.contactEmail && (
                  <p className="mt-1 text-xs text-red-600">{errors.contactEmail.message}</p>
                )}
              </div>
            </div>

            <div>
              <label htmlFor="contactOrganization" className="block text-sm font-medium mb-1">
                Organization/Farm Name
              </label>
              <input
                type="text"
                id="contactOrganization"
                {...register("contactOrganization")}
                className="w-full px-3 py-2 text-sm border rounded"
              />
            </div>

            <div>
              <div className="flex items-center mt-4">
                <input
                  type="checkbox"
                  id="consent"
                  {...register("consent")}
                  className="h-4 w-4 text-blue-600 rounded"
                />
                <label htmlFor="consent" className="ml-2 text-sm">
                  I consent to having my data stored and analyzed for research purposes.
                </label>
              </div>
              {errors.consent && (
                <p className="mt-1 text-xs text-red-600">{errors.consent.message}</p>
              )}
            </div>
          </div>
        </section>


        {/* Damage Information Section */}
        <section className="bg-gray-50 p-4 md:p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4 border-b pb-2">Damage Information</h2>

          <div className="space-y-4">
            {/* Question 1: Damage Type */}
            <div>
              <label className="block text-sm font-medium mb-1">
                What kind of damage are you reporting? (Check all that apply)
              </label>
              <div className="space-y-2">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="damageSeedcornMaggot"
                    {...register("damageType.seedcornMaggot")}
                    className="h-4 w-4 text-blue-600 rounded"
                  />
                  <label htmlFor="damageSeedcornMaggot" className="ml-2 text-sm">
                    Seedcorn Maggot (<i>Delia platura</i>)
                  </label>
                </div>

                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="damageWireworm"
                    {...register("damageType.wireworm")}
                    className="h-4 w-4 text-blue-600 rounded"
                  />
                  <label htmlFor="damageWireworm" className="ml-2 text-sm">
                    Wireworm
                  </label>
                </div>

                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="damageWhiteGrubs"
                    {...register('damageType.whiteGrubs')}
                    className="h-4 w-4 text-blue-600 rounded"
                  />
                  <label htmlFor="damageWhiteGrubs" className="ml-2 text-sm">
                    White Grubs
                  </label>
                </div>


                <div className="flex flex-col">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="damageOther"
                      {...register("damageType.other")}
                      className="h-4 w-4 text-blue-600 rounded"
                    />
                    <label htmlFor="damageOther" className="ml-2 text-sm">
                      Other
                    </label>
                  </div>

                  {damageTypeOther && (
                    <input
                      type="text"
                      {...register("damageTypeOtherText")}
                      placeholder="Please specify"
                      className="mt-2 w-full md:w-2/3 px-3 py-2 text-sm border rounded"
                    />
                  )}
                </div>
              </div>
            </div>

            {/* Question 2: Extension Verification */}
            <div className="space-y-2">
              <label className="block text-sm font-medium">
                Has the damage been verified by an extension provider, pest control advisor, crop consultant, etc?
              </label>
              <div className="flex space-x-4">
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="extensionVerifiedYes"
                    value="yes"
                    {...register("extensionVerified")}
                    className="h-4 w-4 text-blue-600"
                  />
                  <label htmlFor="extensionVerifiedYes" className="ml-2 text-sm">
                    Yes
                  </label>
                </div>

                <div className="flex items-center">
                  <input
                    type="radio"
                    id="extensionVerifiedNo"
                    value="no"
                    {...register("extensionVerified")}
                    className="h-4 w-4 text-blue-600"
                  />
                  <label htmlFor="extensionVerifiedNo" className="ml-2 text-sm">
                    No
                  </label>
                </div>
              </div>

              {extensionProviderVerified && (
                <div className="mt-2">
                  <label htmlFor="extensionAffiliation" className="block text-sm font-medium mb-1">
                    What is their affiliation?
                  </label>
                  <input
                    type="text"
                    id="extensionAffiliation"
                    {...register("extensionAffiliation")}
                    placeholder="Enter affiliation"
                    className="w-full px-3 py-2 text-sm border rounded"
                  />
                </div>
              )}
            </div>

            {/* Question 3: Upload Photo of Damage */}
            <div>
              <label className="block text-sm font-medium mb-1">
                Upload photos of the damage (up to 5 images)
              </label>
              <input
                type="file"
                multiple
                accept="image/*"
                {...register("damagePhotos")}
                className="w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4
                  file:rounded file:border-0 file:text-sm file:font-semibold
                  file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
              />
              <p className="mt-1 text-xs text-gray-500">
                Maximum 5 images. Accepted formats: JPG, PNG, GIF.
              </p>
            </div>

            {/* Question 4-5: Area Affected & Measurement */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="areaAffected" className="block text-sm font-medium mb-1">
                  How much area is affected by stand loss?
                </label>
                <input
                  type="text"
                  id="areaAffected"
                  {...register("areaAffected")}
                  className="w-full px-3 py-2 text-sm border rounded"
                  placeholder="e.g., 5 acres, 30% of field"
                />
                {errors.areaAffected && (
                  <p className="mt-1 text-xs text-red-600">{errors.areaAffected.message}</p>
                )}
              </div>

              <div>
                <label htmlFor="standLossMeasurement" className="block text-sm font-medium mb-1">
                  How did you measure stand loss?
                </label>
                <input
                  type="text"
                  id="standLossMeasurement"
                  {...register("standLossMeasurement")}
                  className="w-full px-3 py-2 text-sm border rounded"
                  placeholder="e.g., plant count, visual assessment"
                />
              </div>
            </div>

            {/* Question 6: Upload Photo of Field */}
            <div>
              <label className="block text-sm font-medium mb-1">
                Upload photos of the field (up to 5 images)
              </label>
              <input
                type="file"
                multiple
                accept="image/*"
                {...register("fieldPhotos")}
                className="w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4
                  file:rounded file:border-0 file:text-sm file:font-semibold
                  file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
              />
              <p className="mt-1 text-xs text-gray-500">
                Maximum 5 images. Accepted formats: JPG, PNG, GIF.
              </p>
            </div>

            {/* Question 7-8: Replanting */}
            <div className="space-y-2">
              <label className="block text-sm font-medium">
                Do you need to replant?
              </label>
              <div className="flex space-x-4">
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="needsReplantingYes"
                    value="yes"
                    {...register("needsReplanting")}
                    className="h-4 w-4 text-blue-600"
                  />
                  <label htmlFor="needsReplantingYes" className="ml-2 text-sm">
                    Yes
                  </label>
                </div>

                <div className="flex items-center">
                  <input
                    type="radio"
                    id="needsReplantingNo"
                    value="no"
                    {...register("needsReplanting")}
                    className="h-4 w-4 text-blue-600"
                  />
                  <label htmlFor="needsReplantingNo" className="ml-2 text-sm">
                    No
                  </label>
                </div>
              </div>

              <div className="mt-2">
                <label htmlFor="replantingDecision" className="block text-sm font-medium mb-1">
                  How did you decide whether or not you will replant?
                </label>
                <textarea
                  id="replantingDecision"
                  {...register("replantingDecision")}
                  placeholder="Describe your decision process"
                  className="w-full px-3 py-2 text-sm border rounded"
                  rows={3}
                ></textarea>
              </div>
            </div>
          </div>
        </section>

        {/* General Field Information Section */}
        <section className="bg-gray-50 p-4 md:p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4 border-b pb-2">General Field Information</h2>

          <div className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <label htmlFor="state" className="block text-sm font-medium mb-1">
                  State
                </label>
                <input
                  type="text"
                  id="state"
                  {...register("state")}
                  className="w-full px-3 py-2 text-sm border rounded"
                  placeholder="e.g., NY, PA, OH"
                />
                {errors.state && (
                  <p className="mt-1 text-xs text-red-600">{errors.state.message}</p>
                )}
              </div>

              <div>
                <label htmlFor="county" className="block text-sm font-medium mb-1">
                  County
                </label>
                <input
                  type="text"
                  id="county"
                  {...register("county")}
                  className="w-full px-3 py-2 text-sm border rounded"
                />
                {errors.county && (
                  <p className="mt-1 text-xs text-red-600">{errors.county.message}</p>
                )}
              </div>

              <div>
                <label htmlFor="fieldSize" className="block text-sm font-medium mb-1">
                  Field Size (acres)
                </label>
                <input
                  type="number"
                  id="fieldSize"
                  {...register("fieldSize", {valueAsNumber: true})}
                  className="w-full px-3 py-2 text-sm border rounded"
                  step="0.1"
                />
                {errors.fieldSize && (
                  <p className="mt-1 text-xs text-red-600">{errors.fieldSize.message}</p>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* Field History Section */}
        <section className="bg-gray-50 p-4 md:p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4 border-b pb-2">Field History</h2>

          <div className="space-y-6">
            {/* Crop Planted Last Season */}
            <div>
              <label htmlFor="cropLastSeason" className="block text-sm font-medium mb-1">
                Crop Planted Last Season
              </label>
              <input
                type="text"
                id="cropLastSeason"
                {...register("cropLastSeason")}
                className="w-full md:w-2/3 px-3 py-2 text-sm border rounded"
              />
            </div>

            {/* Cover Crop Section */}
            <div className="space-y-4">
              <div className="space-y-2">
                <label className="block text-sm font-medium">
                  Cover Crop?
                </label>
                <div className="flex space-x-4">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="coverCropNo"
                      value="no"
                      {...register("coverCrop")}
                      className="h-4 w-4 text-blue-600"
                    />
                    <label htmlFor="coverCropNo" className="ml-2 text-sm">No</label>
                  </div>

                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="coverCropYes"
                      value="yes"
                      {...register("coverCrop")}
                      className="h-4 w-4 text-blue-600"
                    />
                    <label htmlFor="coverCropYes" className="ml-2 text-sm">Yes</label>
                  </div>
                </div>
              </div>

              {hasCoverCrop && (
                <div className="ml-4 space-y-4 border-l-2 border-gray-200 pl-4">
                  <div>
                    <label className="block text-sm font-medium mb-2">
                      If YES, drilled in or broadcasted?
                    </label>
                    <div className="flex space-x-4">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          id="coverCropDrilled"
                          value="drilled"
                          {...register("coverCropMethod")}
                          className="h-4 w-4 text-blue-600"
                        />
                        <label htmlFor="coverCropDrilled" className="ml-2 text-sm">Drilled in</label>
                      </div>

                      <div className="flex items-center">
                        <input
                          type="radio"
                          id="coverCropBroadcasted"
                          value="broadcasted"
                          {...register("coverCropMethod")}
                          className="h-4 w-4 text-blue-600"
                        />
                        <label htmlFor="coverCropBroadcasted" className="ml-2 text-sm">Broadcasted</label>
                      </div>
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-2">
                      Cover Crop Type
                    </label>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="coverCropRye"
                          {...register("coverCropType.rye")}
                          className="h-4 w-4 text-blue-600 rounded"
                        />
                        <label htmlFor="coverCropRye" className="ml-2 text-sm">Rye</label>
                      </div>

                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="coverCropClover"
                          {...register("coverCropType.clover")}
                          className="h-4 w-4 text-blue-600 rounded"
                        />
                        <label htmlFor="coverCropClover" className="ml-2 text-sm">Clover</label>
                      </div>

                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="coverCropBarley"
                          {...register("coverCropType.barley")}
                          className="h-4 w-4 text-blue-600 rounded"
                        />
                        <label htmlFor="coverCropBarley" className="ml-2 text-sm">Barley</label>
                      </div>

                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="coverCropRadishes"
                          {...register("coverCropType.radishes")}
                          className="h-4 w-4 text-blue-600 rounded"
                        />
                        <label htmlFor="coverCropRadishes" className="ml-2 text-sm">Radishes</label>
                      </div>

                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="coverCropAlfalfa"
                          {...register("coverCropType.alfalfa")}
                          className="h-4 w-4 text-blue-600 rounded"
                        />
                        <label htmlFor="coverCropAlfalfa" className="ml-2 text-sm">Alfalfa</label>
                      </div>

                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="coverCropTurnips"
                          {...register("coverCropType.turnips")}
                          className="h-4 w-4 text-blue-600 rounded"
                        />
                        <label htmlFor="coverCropTurnips" className="ml-2 text-sm">Turnips</label>
                      </div>

                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="coverCropOther"
                          {...register("coverCropType.other")}
                          className="h-4 w-4 text-blue-600 rounded"
                        />
                        <label htmlFor="coverCropOther" className="ml-2 text-sm">Other</label>
                      </div>

                      {coverCropTypeOther && <div>
                        <input
                          type="text"
                          {...register("coverCropTypeOther")}
                          placeholder="Other cover crop"
                          className="w-full px-3 py-2 text-sm border rounded"
                        />
                      </div>}
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-2">
                      Cover Crop Termination
                    </label>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="terminationRolling"
                          {...register("coverCropTermination.rolling")}
                          className="h-4 w-4 text-blue-600 rounded"
                        />
                        <label htmlFor="terminationRolling" className="ml-2 text-sm">Rolling/crimping</label>
                      </div>

                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="terminationMowing"
                          {...register("coverCropTermination.mowing")}
                          className="h-4 w-4 text-blue-600 rounded"
                        />
                        <label htmlFor="terminationMowing" className="ml-2 text-sm">Mowing</label>
                      </div>

                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="terminationBurning"
                          {...register("coverCropTermination.burning")}
                          className="h-4 w-4 text-blue-600 rounded"
                        />
                        <label htmlFor="terminationBurning" className="ml-2 text-sm">Burning</label>
                      </div>

                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="terminationHerbicides"
                          {...register("coverCropTermination.herbicides")}
                          className="h-4 w-4 text-blue-600 rounded"
                        />
                        <label htmlFor="terminationHerbicides" className="ml-2 text-sm">Herbicides</label>
                      </div>

                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="terminationIncorporation"
                          {...register("coverCropTermination.incorporation")}
                          className="h-4 w-4 text-blue-600 rounded"
                        />
                        <label htmlFor="terminationIncorporation" className="ml-2 text-sm">Incorporation</label>
                      </div>

                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="terminationOther"
                          {...register("coverCropTermination.other")}
                          className="h-4 w-4 text-blue-600 rounded"
                        />
                        <label htmlFor="terminationOther" className="ml-2 text-sm">Other</label>
                      </div>

                      {coverCropTerminationOther && <div className="sm:col-span-2">
                        <input
                          type="text"
                          {...register("coverCropTerminationOther")}
                          placeholder="Other termination method"
                          className="w-full px-3 py-2 text-sm border rounded"
                        />
                      </div>}
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-2">
                      Cover Crop Termination Date
                    </label>
                    <div className="grid grid-cols-3 gap-2">
                      <div>
                        <select
                          {...register("coverCropTerminationDate.month")}
                          className="w-full px-3 py-2 text-sm border rounded"
                        >
                          <option value="">Month</option>
                          <option value="1">January</option>
                          <option value="2">February</option>
                          <option value="3">March</option>
                          <option value="4">April</option>
                          <option value="5">May</option>
                          <option value="6">June</option>
                          <option value="7">July</option>
                          <option value="8">August</option>
                          <option value="9">September</option>
                          <option value="10">October</option>
                          <option value="11">November</option>
                          <option value="12">December</option>
                        </select>
                      </div>

                      <div>
                        <input
                          type="number"
                          {...register("coverCropTerminationDate.day")}
                          placeholder="Day"
                          min="1"
                          max="31"
                          className="w-full px-3 py-2 text-sm border rounded"
                        />
                      </div>

                      <div>
                        <input
                          type="number"
                          {...register("coverCropTerminationDate.year")}
                          placeholder="Year"
                          min="2000"
                          max="2100"
                          className="w-full px-3 py-2 text-sm border rounded"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Manure Section */}
            <div className="space-y-4">
              <div className="space-y-2">
                <label className="block text-sm font-medium">
                  Manure?
                </label>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="manureNo"
                      value="no"
                      {...register("manure")}
                      className="h-4 w-4 text-blue-600"
                    />
                    <label htmlFor="manureNo" className="ml-2 text-sm">No</label>
                  </div>

                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="manureCow"
                      value="cow"
                      {...register("manure")}
                      className="h-4 w-4 text-blue-600"
                    />
                    <label htmlFor="manureCow" className="ml-2 text-sm">Cow/Dairy</label>
                  </div>

                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="manureChicken"
                      value="chicken"
                      {...register("manure")}
                      className="h-4 w-4 text-blue-600"
                    />
                    <label htmlFor="manureChicken" className="ml-2 text-sm">Chicken</label>
                  </div>

                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="manureOther"
                      value="other"
                      {...register("manure")}
                      className="h-4 w-4 text-blue-600"
                    />
                    <label htmlFor="manureOther" className="ml-2 text-sm">Other</label>
                  </div>

                  {hasManure && manure === "other" && (
                    <div className="sm:col-span-2">
                      <input
                        type="text"
                        {...register("manureOther")}
                        placeholder="Other manure type"
                        className="w-full px-3 py-2 text-sm border rounded"
                      />
                    </div>
                  )}
                </div>
              </div>

              {hasManure && (
                <div className="ml-4 space-y-4 border-l-2 border-gray-200 pl-4">
                  <div>
                    <label className="block text-sm font-medium mb-1">
                      Application Date
                    </label>
                    <div className="grid grid-cols-3 gap-2">
                      <div>
                        <select
                          {...register("manureApplication.month")}
                          className="w-full px-3 py-2 text-sm border rounded"
                        >
                          <option value="">Month</option>
                          <option value="1">January</option>
                          <option value="2">February</option>
                          <option value="3">March</option>
                          <option value="4">April</option>
                          <option value="5">May</option>
                          <option value="6">June</option>
                          <option value="7">July</option>
                          <option value="8">August</option>
                          <option value="9">September</option>
                          <option value="10">October</option>
                          <option value="11">November</option>
                          <option value="12">December</option>
                        </select>
                      </div>

                      <div>
                        <input
                          type="number"
                          {...register("manureApplication.day")}
                          placeholder="Day"
                          min="1"
                          max="31"
                          className="w-full px-3 py-2 text-sm border rounded"
                        />
                      </div>

                      <div>
                        <input
                          type="number"
                          {...register("manureApplication.year")}
                          placeholder="Year"
                          min="2000"
                          max="2100"
                          className="w-full px-3 py-2 text-sm border rounded"
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label htmlFor="manureApplicationRate" className="block text-sm font-medium mb-1">
                      Application Rate
                    </label>
                    <input
                      type="text"
                      id="manureApplicationRate"
                      {...register("manureApplicationRate")}
                      placeholder="e.g., tons/acre"
                      className="w-full md:w-2/3 px-3 py-2 text-sm border rounded"
                    />
                  </div>
                </div>
              )}
            </div>

            {/* Tillage Section */}
            <div className="space-y-4">
              <div className="space-y-2">
                <label className="block text-sm font-medium">
                  Tillage?
                </label>
                <div className="flex space-x-4">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="tillageNo"
                      value="no"
                      {...register("tillage")}
                      className="h-4 w-4 text-blue-600"
                    />
                    <label htmlFor="tillageNo" className="ml-2 text-sm">No</label>
                  </div>

                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="tillageYes"
                      value="yes"
                      {...register("tillage")}
                      className="h-4 w-4 text-blue-600"
                    />
                    <label htmlFor="tillageYes" className="ml-2 text-sm">Yes</label>
                  </div>
                </div>
              </div>

              {hasTillage && (
                <div className="ml-4 space-y-4 border-l-2 border-gray-200 pl-4">
                  <div>
                    <label className="block text-sm font-medium mb-2">
                      Tillage Type
                    </label>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="tillageReduced"
                          {...register("tillageType.reduced")}
                          className="h-4 w-4 text-blue-600 rounded"
                        />
                        <label htmlFor="tillageReduced" className="ml-2 text-sm">Reduced</label>
                      </div>

                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="tillageConventional"
                          {...register("tillageType.conventional")}
                          className="h-4 w-4 text-blue-600 rounded"
                        />
                        <label htmlFor="tillageConventional" className="ml-2 text-sm">Conventional</label>
                      </div>

                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="tillageStripZonal"
                          {...register("tillageType.stripZonal")}
                          className="h-4 w-4 text-blue-600 rounded"
                        />
                        <label htmlFor="tillageStripZonal" className="ml-2 text-sm">Strip/Zonal</label>
                      </div>

                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="tillageMouldboard"
                          {...register("tillageType.mouldboard")}
                          className="h-4 w-4 text-blue-600 rounded"
                        />
                        <label htmlFor="tillageMouldboard" className="ml-2 text-sm">Mouldboard</label>
                      </div>

                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="tillageTined"
                          {...register("tillageType.tined")}
                          className="h-4 w-4 text-blue-600 rounded"
                        />
                        <label htmlFor="tillageTined" className="ml-2 text-sm">Tined/Vertical</label>
                      </div>

                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="tillageDisc"
                          {...register("tillageType.disc")}
                          className="h-4 w-4 text-blue-600 rounded"
                        />
                        <label htmlFor="tillageDisc" className="ml-2 text-sm">Disc plough</label>
                      </div>
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-2">
                      Tillage Date
                    </label>
                    <div className="grid grid-cols-3 gap-2">
                      <div>
                        <select
                          {...register("tillageDate.month")}
                          className="w-full px-3 py-2 text-sm border rounded"
                        >
                          <option value="">Month</option>
                          <option value="1">January</option>
                          <option value="2">February</option>
                          <option value="3">March</option>
                          <option value="4">April</option>
                          <option value="5">May</option>
                          <option value="6">June</option>
                          <option value="7">July</option>
                          <option value="8">August</option>
                          <option value="9">September</option>
                          <option value="10">October</option>
                          <option value="11">November</option>
                          <option value="12">December</option>
                        </select>
                      </div>

                      <div>
                        <input
                          type="number"
                          {...register("tillageDate.day")}
                          placeholder="Day"
                          min="1"
                          max="31"
                          className="w-full px-3 py-2 text-sm border rounded"
                        />
                      </div>

                      <div>
                        <input
                          type="number"
                          {...register("tillageDate.year")}
                          placeholder="Year"
                          min="2000"
                          max="2100"
                          className="w-full px-3 py-2 text-sm border rounded"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>

        {/* Management Section */}
        <section className="bg-gray-50 p-4 md:p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4 border-b pb-2">Management</h2>

          <div className="space-y-6">
            {/* Planting Information */}
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-2">
                  Planting Date
                </label>
                <div className="grid grid-cols-3 gap-2">
                  <div>
                    <select
                      {...register("plantingDate.month")}
                      className="w-full px-3 py-2 text-sm border rounded"
                    >
                      <option value="">Month</option>
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                  </div>

                  <div>
                    <input
                      type="number"
                      {...register("plantingDate.day")}
                      placeholder="Day"
                      min="1"
                      max="31"
                      className="w-full px-3 py-2 text-sm border rounded"
                    />
                  </div>

                  <div>
                    <input
                      type="number"
                      {...register("plantingDate.year")}
                      placeholder="Year"
                      min="2000"
                      max="2100"
                      className="w-full px-3 py-2 text-sm border rounded"
                    />
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label htmlFor="seedVariety" className="block text-sm font-medium mb-1">
                    Seed Variety Name
                  </label>
                  <input
                    type="text"
                    id="seedVariety"
                    {...register("seedVariety")}
                    className="w-full px-3 py-2 text-sm border rounded"
                  />
                </div>

                <div>
                  <label htmlFor="seedingRate" className="block text-sm font-medium mb-1">
                    Seeding Rate (per acre)
                  </label>
                  <input
                    type="number"
                    id="seedingRate"
                    {...register("seedingRate")}
                    step="1"
                    min="0"
                    className="w-full px-3 py-2 text-sm border rounded"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="rowSpacing" className="block text-sm font-medium mb-1">
                  Row Spacing (inches)
                </label>
                <input
                  type="number"
                  id="rowSpacing"
                  {...register("rowSpacing", {valueAsNumber: true})}
                  step="0.5"
                  min="0"
                  className="w-1/3 px-3 py-2 text-sm border rounded"
                />
              </div>
            </div>

            {/* Seed Treatment Section */}
            <div>
              <label className="block text-sm font-medium mb-2">
                Seed Treatment
              </label>
              <p className="text-xs text-gray-500 mb-2">
                (Alternatively, send a photo of the seed label to poveda.agroecology@gmail.com)
              </p>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="treatmentCruiserMaxx"
                    {...register("seedTreatment.cruiserMaxx")}
                    className="h-4 w-4 text-blue-600 rounded"
                  />
                  <label htmlFor="treatmentCruiserMaxx" className="ml-2 text-sm">Cruiser Maxx</label>
                </div>

                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="treatmentPoncho"
                    {...register("seedTreatment.poncho")}
                    className="h-4 w-4 text-blue-600 rounded"
                  />
                  <label htmlFor="treatmentPoncho" className="ml-2 text-sm">Poncho 250/Lumisure</label>
                </div>

                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="treatmentOther"
                    {...register("seedTreatment.other")}
                    className="h-4 w-4 text-blue-600 rounded"
                  />
                  <label htmlFor="treatmentOther" className="ml-2 text-sm">Other</label>
                </div>

                {seedTreatmentOther && <div>
                  <input
                    type="text"
                    {...register("seedTreatmentOther")}
                    placeholder="Other seed treatment"
                    className="w-full px-3 py-2 text-sm border rounded"
                  />
                </div>}
              </div>
            </div>
          </div>
        </section>

        {/* Submit Button */}
        <div className="flex justify-center">
          <button
            type="submit"
            disabled={isSubmitting}
            className="px-6 py-3 bg-blue-600 text-white font-medium rounded-lg shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isSubmitting ? "Submitting..." : "Submit Report"}
          </button>
        </div>
      </form>}
    </div>
  );
};

export default PestDamageReportForm;
