import Seo from "../components/seo"
import PestDamageReportForm from "../components/PestDamageReportingForm";
import Layout from "../components/layout";
import useStations from "../utils/hooks/useStations"
import * as React from "react";

const PestReportPage: React.FC = () => {
  const {station} = useStations();
  return (
    <Layout station={station} h1Text="Report Pest Damage">
      <Seo title="Report Seed Pest Damage"/>

      <div className="container px-3 mx-auto container-article xl:pt-6 lg:px-4 xl:px-12">

        <PestDamageReportForm/>

      </div>
    </Layout>
  );
};

export default PestReportPage;
